import Flickity from 'flickity';

import 'flickity-imagesloaded';

const selectors = {
  mainCarousel: '[data-image-carousel]',
};

/**
 * Dynamic section for use throughout the site
 *
 * @export
 * @class DynamicImageSlider
 */
export default class DynamicImageSlider {
  constructor(section) {
    this.el = section.el;

    this._initCarousel();
    this._resizeCarousel();
  }

  /**
   * Initialize primary image carousel
   */
  _initCarousel() {
    this.flickity = new Flickity(
      this.el.querySelector(selectors.mainCarousel),
      this._getSettings(),
    );
  }

  // Get the settings from the script with the appropiate section id.
  _getSettings() {
    const sectionSettings = this.el.querySelector('script[data-section-id]');
    const defaultSettings = {
      adaptiveHeight: true,
      prevNextButtons: false,
      pageDots: false,
      cellAlign: 'left',
      imagesLoaded: true,
      percentPosition: true,
    };

    const customizableSettings = {
      freeScroll:
        sectionSettings.getAttribute('data-section-free-scroll') === 'true',
      wrapAround:
        sectionSettings.getAttribute('data-section-wrap-around') === 'true',
    };

    return {
      ...defaultSettings,
      ...customizableSettings,
    };
  }

  _resizeCarousel() {
    onload = () => {
      this.flickity.resize();
    };
  }
}
