import { getTranslation } from '../helpers/utilities';
import { h } from 'preact';
import { useCartContext } from '../context/CartContext';

const CartRemove = ({ item }) => {
  const { removeLineItem } = useCartContext();

  return (
    <button
      class="type-overline button p-0 text-xs  underline"
      aria-label={getTranslation('cart.remove_item')}
      type="button"
      onClick={() => removeLineItem(item.id)}
    >
      {getTranslation('cart.remove')}
    </button>
  );
};

export default CartRemove;
