import { createPortal } from 'preact/compat';
import { getTranslation } from '../helpers/utilities';
import { useCartContext } from '../context/CartContext';
import useEmptyPortal from '../hooks/useEmptyPortal';

import { h } from 'preact';

const MiniCartEl = document.getElementById('preact-mini-cart');

const MiniCart = () => {
  const { cart, toggleMiniCart } = useCartContext();
  const emptied = useEmptyPortal(MiniCartEl);

  if (!emptied) {
    return null;
  }

  return createPortal(
    <button
      class="button button--link relative h-full rounded-none"
      onClick={() => {
        toggleMiniCart(true);
      }}
    >
      <svg
        class="icon--cart h-5 w-5"
        focusable="false"
        role="presentation"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 31 30"
      >
        <title id="{{ icon_title_id }}">{getTranslation('icons.cart')}</title>
        <path
          d="M21.825 16.5C22.3609 16.5017 22.8874 16.3592 23.3493 16.0874C23.8112 15.8155 24.1914 15.4244 24.45 14.955L29.82 5.22C29.9464 4.99264 30.0113 4.73621 30.0082 4.47608C30.005 4.21596 29.934 3.96116 29.8022 3.73689C29.6704 3.51263 29.4823 3.32667 29.2565 3.19743C29.0307 3.06818 28.7751 3.00013 28.515 3H6.315L4.9 0H0V3H3L8.4 14.385L6.375 18.045C6.12322 18.502 5.99479 19.0167 6.00233 19.5385C6.00987 20.0602 6.15314 20.571 6.41802 21.0205C6.68291 21.4701 7.06029 21.8429 7.51301 22.1023C7.96574 22.3617 8.47822 22.4988 9 22.5H27V19.5H9L10.65 16.5H21.825ZM7.74 6H25.965L21.825 13.5H11.3L7.74 6ZM9 24C8.40666 24 7.82664 24.1759 7.33329 24.5056C6.83994 24.8352 6.45542 25.3038 6.22836 25.852C6.0013 26.4001 5.94189 27.0033 6.05764 27.5853C6.1734 28.1672 6.45912 28.7018 6.87868 29.1213C7.29824 29.5409 7.83279 29.8266 8.41473 29.9424C8.99667 30.0581 9.59987 29.9987 10.1481 29.7716C10.6962 29.5446 11.1648 29.1601 11.4944 28.6667C11.8241 28.1734 12 27.5933 12 27C12 26.2044 11.6839 25.4413 11.1213 24.8787C10.5587 24.3161 9.79565 24 9 24ZM24 24C23.4067 24 22.8266 24.1759 22.3333 24.5056C21.8399 24.8352 21.4554 25.3038 21.2284 25.852C21.0013 26.4001 20.9419 27.0033 21.0576 27.5853C21.1734 28.1672 21.4591 28.7018 21.8787 29.1213C22.2982 29.5409 22.8328 29.8266 23.4147 29.9424C23.9967 30.0581 24.5999 29.9987 25.148 29.7716C25.6962 29.5446 26.1648 29.1601 26.4944 28.6667C26.8241 28.1734 27 27.5933 27 27C27 26.2044 26.6839 25.4413 26.1213 24.8787C25.5587 24.3161 24.7956 24 24 24Z"
          fill="currentColor"
        />
      </svg>
      <span class="absolute -right-3 -top-3 flex h-5 min-w-[20px] items-center justify-center rounded-full bg-primary-2 p-1 text-xs font-normal text-white">
        {cart.items ? cart.item_count : 0}
      </span>
    </button>,
    MiniCartEl,
  );
};

export default MiniCart;
