import DropDown from '@knitagency/agency-utils/dist/DropDown';

export default class StaticFaq {
  constructor(section) {
    this._initDropDown(section);
  }

  _initDropDown(section) {
    const dropdowns = section.el.querySelectorAll('[data-faq-block]');
    [...dropdowns].forEach(
      dropdown =>
        new DropDown({
          el: dropdown,
          triggerClass: 'faq-trigger',
          contentClass: 'faq-content',
          closeOnClickOutside: false,
        }),
    );
  }
}
