import Scroll from '../helpers/scroll';
import Search from '../components/search/Search';
import scrollLock from 'scroll-lock';

const selectors = {
  mobileMenuTrigger: '[data-mobile-menu-trigger]',
  closeMobileMenu: '[data-navigation-close]',
  navigationWrapper: '[data-navigation-wrapper]',
};

const cssClasses = {
  hidden: 'hidden',
  navActive: 'main-nav-item--active',
};
export default class StaticHeader {
  constructor(section) {
    this.section = section;

    const { settings } = section.data;

    if (settings.enable_fixed_header) {
      this.listener = new Scroll(this.onScroll, this.section.el);
      this.section.el.className +=
        ' sticky top-0 z-50 bg-cream transition-transform';
    }

    this.search = new Search(section);
    this._initMobileNav();
  }

  _initMobileNav = () => {
    this.isMobileNavHidden = true;

    this.navList = this.section.el.querySelector(selectors.navList);
    this.navigationWrapper = this.section.el.querySelector(
      selectors.navigationWrapper,
    );
    const mobileMenuTrigger = this.section.el.querySelector(
      selectors.mobileMenuTrigger,
    );
    const mobileMenuClose = this.section.el.querySelector(
      selectors.closeMobileMenu,
    );
    if (mobileMenuTrigger) {
      mobileMenuTrigger.addEventListener('click', this._toggleMobileNav);
    }
    if (mobileMenuClose) {
      mobileMenuClose.addEventListener('click', this._closeMobileNav);
    }
  };

  _toggleMobileNav = () => {
    if (this.isMobileNavHidden) {
      this._openMobileNav();
    } else {
      this._closeMobileNav();
    }
  };

  _openMobileNav = () => {
    this.navigationWrapper.classList.remove(cssClasses.hidden);
    this.isMobileNavHidden = false;
    scrollLock.disablePageScroll();
  };

  _closeMobileNav = () => {
    this.navigationWrapper.classList.add(cssClasses.hidden);
    if (this.slidingNav) {
      this.slidingNav.destroy();
    }
    this.isMobileNavHidden = true;
    scrollLock.enablePageScroll();
  };

  onScroll = direction => {
    if (direction === 'up') {
      this.showMenu();
    } else if (direction === 'down') {
      this.hideMenu();
    }
  };

  showMenu = () => {
    this.section.el.classList.remove('-translate-y-full');
    this.section.el.classList.add('-translate-y-0');
  };

  hideMenu = () => {
    this.section.el.classList.add('-translate-y-full');
    this.section.el.classList.remove('-translate-y-0');
  };
}
