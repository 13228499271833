import CartEmpty from './CartEmpty';
import CartItem from './CartItem';
import CartTotals from './CartTotals';
import CartCrossSell from './CartCrossSell';
import ShippingProgressBar from '../shared-components/ShippingProgressBar';
import ShippingProgressText from '../shared-components/ShippingProgressText';
import cn from 'classnames';
import { createPortal } from 'preact/compat';
import { useCartContext } from '../context/CartContext';
import { getDataForCart, getTranslation } from '../helpers/utilities';

import { h } from 'preact';

const MiniCartDrawerEl = document.getElementById('preact-mini-cart-drawer');

const transitionLength = 300;

const MiniCart = () => {
  const { cart, isMiniCartOpen, toggleMiniCart, miniCartData } =
    useCartContext();
  const dataForCart = getDataForCart();

  return createPortal(
    <div
      class={cn(
        'fixed inset-0',
        isMiniCartOpen
          ? 'opacity-1 visible'
          : `invisible opacity-0 delay-${transitionLength}`,
      )}
    >
      <div
        onClick={() => toggleMiniCart(false)}
        class={cn(
          `absolute inset-0 bg-grey-1 transition-opacity duration-${transitionLength}`,
          isMiniCartOpen ? 'opacity-50' : 'opacity-0',
        )}
      />
      <div
        class={cn(
          `absolute bottom-0 right-0 top-0 z-50 flex w-11/12 flex-col bg-cream pb-11 transition-transform sm:w-full sm:max-w-md md:max-w-xl duration-${transitionLength} md:px-12`,
          isMiniCartOpen ? 'translate-x-0' : 'translate-x-full',
        )}
      >
        <div class="flex flex-wrap justify-between border-b border-primary-2 px-4 py-4 md:px-0 md:py-9">
          <div>
            <h3 class="type-heading-4 text-primary-2">
              {getTranslation('mini_cart.title')}
            </h3>
            {!dataForCart.customerIsWholesale &&
              dataForCart.showFreeShippingBar && (
                <ShippingProgressText
                  freeShippingThreshold={dataForCart.freeShippingThreshold}
                />
              )}
          </div>
          <button
            onClick={() => toggleMiniCart(false)}
            class="flex h-8 w-8 items-center justify-center rounded-full bg-primary-2"
          >
            <svg
              className="h-4 text-white"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>

          {!dataForCart.customerIsWholesale &&
            dataForCart.showFreeShippingBar && (
              <ShippingProgressBar
                freeShippingThreshold={dataForCart.freeShippingThreshold}
              />
            )}
        </div>

        <div
          class="hide-scrollbar overflow-auto px-4 md:px-0"
          data-scroll-lock-scrollable
          data-cart-items
        >
          {cart.items && cart.items.length > 0 ? (
            cart.items.map(item => <CartItem item={item} key={item.id} />)
          ) : (
            <CartEmpty />
          )}

          {cart.items &&
          cart.items.length > 0 &&
          miniCartData.settings.showUpsells ? (
            <CartCrossSell />
          ) : null}
        </div>

        <div class="mt-auto w-full border-t border-primary-2 bg-cream px-4 md:px-0">
          {cart.total_price > 0 && <CartTotals cart={{ ...cart }} />}

          <div class="mt-2 flex items-center justify-between">
            <form action="/cart" method="POST" class="w-full">
              <button
                type="submit"
                name="checkout"
                class="button button--primary button--full"
              >
                {getTranslation('mini_cart.checkout')}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>,
    MiniCartDrawerEl,
  );
};

export default MiniCart;
